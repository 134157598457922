@import url(https://fonts.googleapis.com/css?family=Work+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
  font-family: Agrandir;
  src:  url(/static/media/Agrandir-GrandBold.0303549a.eot) format('eot');
  src:  url(/static/media/Agrandir-GrandBold.01a5b022.woff2) format('woff2'), 
        url(/static/media/Agrandir-GrandBold.b663d665.woff) format('woff'),
        url(/static/media/Agrandir-GrandBold.d33c23b5.ttf) format('truetype');
  font-display: block;
        
}


.App {
  text-align: center;
}

/* .Header-top-border{
  height: 38px;
  background-color: #fc5350;
} */
@media only screen and (max-width: 600px) {
  .Header-textbox{
    margin-left: 2rem;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) {
  .Header-textbox{
    margin-left: 6rem;
    text-align: left;
  }
}

#backgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  display: block;
  margin: 0 auto;
}

.App-header {
  height: 100vh;
  /* background-image: url(./lente-cabecera.png), url(./imagen-cabecera.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center, center bottom; */
  display: flex;
  justify-content: left; /* align horizontal */
  align-items: center; /* align vertical */
}

.Header-band-title{
  font-family: "Agrandir";
  font-weight: bold;
  font-size: 4rem;
  line-height: 3rem;
  text-align: left ;
  color: #fff;
  margin-top: 20px;
}

.Header-release{
  font-family: "Work Sans";
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left ;
  color: #fff;
}
.Header-release a{
  color: #fff
}

.Header-social-links{
  margin-top: 30px;
}

.Header-social-links a{
  font-family: "Agrandir";
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  text-align: left ;
  color: #fff;
}

.Header-social-links p{
  line-height: 0.5rem;
}

.Body-band-container{
  padding-top: 50px;
  padding-bottom: 50px;
}

.Body-band-picture img{
  width: 100%;
}

.Body-band-info{
  font-family: "Work Sans";
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left ;
  align-items: center;
  display: flex;
  color: white;
}

.Body-concerts-title{
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  font-family: "Agrandir";
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  vertical-align: middle;
  color: white;
  position: relative;
  top: 100px;
}

.Body-concerts-container{
  padding-top: 10rem;
  padding-bottom: 17rem;
  /* background: #f8f8f8; */
}


@media only screen and (max-width: 600px) {
  .Body-concerts-list{
    font-family: "Agrandir";
    font-size: 0.6rem;
    text-align: left ;
    color: white;
  }
} 

@media only screen and (min-width: 600px) {
  .Body-concerts-list{
    font-family: "Agrandir";
    font-size: 1rem;
    text-align: left ;
    color: white;
  }
}

.Body-concerts-item{
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.Body-concerts-item a{
  color: white;
}

.Body-subscribe-container{
  /* background: #efefef; */
  font-family: "Agrandir";
  font-weight: bold;
  font-size: 1rem;
  line-height: 3rem;
  text-align: left ;
  color: #505050;
  padding: 20px 20px 20px 20px;
}

.Subscribe-email-field{
  background-color: white;
}

.hidden-element{
  position: absolute;
  left: -5000px;
}

.Subscribe-button{
  font-family: "Agrandir";
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 3rem;
  color: #fff;
  background: #f8534f;
  -webkit-appearance: none;
}

.Footer-social-links{
  padding-top: 25px;
  font-family: "Work Sans";
  font-size: 1.2rem;
  line-height: 25px;
  text-align: center;
  color: white;
}

.Footer-social-links a{
  color: white;
}

.Footer-band-name{
  font-family: "Agrandir";
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
  color: white;
  padding-top: 30px;
  margin-bottom: 100px;
}

.Footer-release{
  padding-top: 25px;
  font-family: "Work Sans";
  font-size: 1rem;
  line-height: 25px;
  text-align: center;
  color: white;
}

.Footer-credits{
  padding-top: 30px;
  padding-bottom: 20px;
  font-family: "Work Sans";
  font-size: 0.8rem;
  line-height: 25px;
  text-align: center;
  color: white;
}

.Footer-bottom-border{
  height: 38px;
  background-color: #fc5350;
}

